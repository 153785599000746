.eyes {
    h2 {
        font-weight: 700;
        text-align: center;
    }

    .banner {
        margin: 20px 0px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .heading {
        p {
            font-weight: 400;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .products-list {
        .product-stats {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            p {
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }
}