.diy-brows {

    .brow-diy-row{
        background-color: #e4e1df;
        border-radius: 10px;
    }

    .brow-diy{
        background-color: #e4e1df;
        padding: 20px;
        border-radius: 10px;
    }

    h2 {
        font-weight: 700;
        text-align: center;
    }

    .banner {
        margin: 20px 0px;

        img {
            width: 100%;
            height: auto;
        }
    }

    h3 {
        text-align: center;
    }

    .video-frame {
        width: 100%;
        height: auto;   
    }

    .youtube-video-frame {
        width: 100%;
        margin-top: 10px;
    }

    h5 {
        text-align: center;
    }

    .video-row{
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .video-box-col{
        // padding-bottom: 50px;
    }
}

.diy-brows-product-box{
    border: 1px solid red !important;
}