.product-loading {
    position: relative;
    .product-box {
        .product-img {
            .img-loading {
                width: 100%;
                height: 329px;
            }
        }
    }

    .product-desc {
        background-color: #F4F4F4;
        padding: 10px 15px;

        .product-name-loader {
            height: 20px;
            margin-bottom: 8px;
        }

        .ratings-loading {
            width: 150px;
            height: 20px;
        }

        .add-to-bag {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
            background-color: #FFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 0px !important;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;

            h5 {
                font-size: 16px;
                font-weight: 600;
                color: #000;
                margin-bottom: 0px;
            }

            .price-loading {
                width: 50px;
            }
        }
    }

    .wishlist-option {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #FFF;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        z-index: 100;

        .wishlist-icon {
            font-size: 20px;
        }
    }
}