.my-account {
    min-height: calc(100vh - 500px);

    .account-tabs {
        font-size: 14px;
        padding: 15px 20px;
        border-radius: 0px !important;
        color: #000;
    }

    .nav-pills {
        background-color: #F5F5F5;
        border-radius: 5px;
    }

    .nav-link:hover {
        background-color: rgba(128, 128, 128, 0.3);
    }

    .nav-link.active {
        background-color: transparent;
        color: #000;
        font-weight: 700;
    }

    .phone-number-select .PhoneInputCountry {
        border: 1px solid #E2E9E1;
        border-radius: 4px;
        padding: 15px;
        height: 45px;
        background-color: #FFF;
    }

    .phone-number-select .PhoneInputCountry .PhoneInputCountrySelect option {
        color: #465B52;
        font-size: 15px;
        margin-bottom: 10px;
    }

    .phone-number-select .PhoneInputInput {
        border: 1px solid #E2E9E1;
        border-radius: 4px;
        height: 45px;
        box-shadow: none;
        color: #161C27;
        font-size: 14px;
        padding: 6px 12px 6px 20px;
        width: 100%;
    }

    .phone-number-select .PhoneInputInput::placeholder {
        font-size: 13px;
    }

    .account-info {
        h3 {
            font-weight: 700;
            font-size: 25px;
        }

        .info-title {
            margin-top: 20px;
            border-bottom: 1px solid #E5E5E5;

            h5 {
                font-weight: 400;
            }
        }

        .account-information {
            display: flex;
            align-items: flex-start;
            margin-top: 15px;

            .information-detail {
                width: 250px;

                h5 {
                    font-size: 16px;
                    font-weight: 700;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 14px;
                }

                .action-link {
                    display: block;
                    text-decoration: none;
                    font-size: 14px;
                    font-style: italic;
                    color: #000;
                }
            }
        }

        .login_wrap h3 {
            font-weight: 700;
            font-size: 25px;
        }

        .login_wrap p {
            font-weight: 400;
            font-size: 15px;
            margin-bottom: 5px;
            color: #465B52;
        }

        .login_wrap .login-form .login-btn {
            background-color: #005CB9;
            border-color: #005CB9;
            box-shadow: none;
            padding: 8px 25px;
            color: #FFF;
            border-radius: 5px;
            font-size: 15px;
        }

        .login_wrap .login-form .form-group .form-control {
            border: 1px solid #E2E9E1;
            border-radius: 4px;
            height: 45px;
            box-shadow: none;
            padding-left: 20px;
            color: #161C27;
            font-size: 14px;
            width: 100%;
        }

        .forgot-pass {
            margin-bottom: 15px;
            font-size: 15px;
            font-weight: 500;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            a {
                text-decoration: none;
                color: #465B52;
                cursor: pointer;
            }
        }

        .login_wrap .login-form .login-btn {
            background-color: #000;
            border-color: #000;
            box-shadow: none;
            padding: 8px 25px;
            color: #FFF;
            border-radius: 5px;
            font-size: 15px;
        }

        .password-icon {
            background-color: #FFF;
            padding: 0px;
            position: absolute;
            top: 10px;
            right: 15px;
            border: 0px solid transparent;
        }

        .password-icon:hover {
            background-color: #FFF;
        }

        .password-icon svg {
            fill: #000;
        }


    }

    .orders-info {
        h3 {
            font-weight: 700;
            font-size: 25px;
        }

        .orders-table {
            margin-top: 10px;
            width: 100%;
            border: 1px solid #E5E5E5;

            th {
                padding: 0.5rem;
                border: 1px solid #E5E5E5;
            }

            td {
                padding: 0.5rem;
                border: 1px solid #E5E5E5;
            }


        }




    }

    .address-information {
        h3 {
            font-weight: 700;
            font-size: 25px;
        }

        .shipping-address {
            h5 {
                font-weight: 600;
                font-size: 18px;
            }

            .address-box {
                position: relative;
                border: 2px solid rgb(225, 225, 225);
                border-radius: 5px;
                padding: 15px 20px;
                cursor: pointer;
                margin-top: 5px;
            }


            .address-box .selected {
                border: 2px solid green;
            }

            .selected {
                border: 2px solid green;
            }

            .address-box.selected .selected-icon {
                display: block;
            }

            .address-box:hover {
                border: 2px solid green;
            }

            .address-box .selected-icon {
                display: none;
                position: absolute;
                top: 5px;
                right: 5px;
            }

            .address-box .address-details {
                position: relative;
            }

            .address-box .address-details p {
                font-size: 14px;
                margin-bottom: 0px;
            }

            .address-box .address-details .address {
                margin-top: 0px;
            }

            .address-box .address-details .actions {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;
            }

            .address-box .address-details .actions .edit-btn {
                padding: 0px;
                color: grey;
                font-style: italic;
                background-color: #FFF;
                border: 0px solid transparent;
                font-size: 14px;
                font-weight: 600;
            }

            .address-box .address-details .actions .edit-btn:hover {
                text-decoration: underline;
            }


            .address-box .address-details .actions .delete-btn {
                padding: 0px;
                color: red;
                font-style: italic;
                background-color: #FFF;
                border: 0px solid transparent;
                font-size: 14px;
                font-weight: 600;
                margin-left: 15px;
            }

            .address-box .address-details .actions .delete-btn:hover {
                text-decoration: underline;
            }

            .address-box.selected .selected-icon {
                display: block;
            }

            .address-box .selected-icon {
                display: none;
                position: absolute;
                top: 5px;
                right: 5px;
            }

            .add-new-address-btn {
                font-family: 'Figtree', sans-serif;
                font-weight: 700;
                padding: 0px;
                background-color: #FFF;
                border: 0px solid transparent;
                color: #000;
                font-size: 15px;
            }

            .add-new-address-btn:hover {
                background-color: #FFF;
                color: #000;
                text-decoration: underline;
            }

            .shipping-address-details {
                margin-top: 10px;

                .create-account-box {
                    display: flex;
                    align-items: center;
                    margin-top: 25px;
                    border: 1px solid #E2E9E1;
                    border-radius: 5px;
                    padding: 15px 15px;

                    .radio-control {
                        height: 20px;
                        width: 20px;
                    }

                    .account-text {
                        margin-left: 15px;

                        h5 {
                            margin-bottom: 0px;
                        }

                        p {
                            margin-bottom: 0px;
                            font-size: 15px;
                        }
                    }
                }
            }

            .address-box-add {
                cursor: pointer;
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                padding: 15px;
                margin-top: 5px;

                p {
                    font-size: 14px;
                    margin-bottom: 0px;
                }
            }

            .address-box-add:hover {
                border: 1px solid #000;
            }

        }
    }

    .user-acc-info {
        h3 {
            font-weight: 700;
            font-size: 25px;
        }

        .user-details {
            .form-group {
                label {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .image-item {
                    position: relative;

                    img {
                        height: 100px;
                        width: 100px;
                        border-radius: 5px;
                        margin-top: 10px;
                        object-fit: cover;
                        margin-bottom: 10px;
                    }

                    .delete {
                        cursor: pointer;
                        position: absolute;
                        left: 88px;
                        top: -4px;
                        background-color: #000;
                        padding: 2px 5px;
                        border-radius: 50%;
                    }

                    .delete-icon {
                        fill: #FFF;
                    }
                }
            }
        }

        .save-btn {
            background-color: #000;
            border-color: #000;
            padding: 5px 25px;
        }
    }


}

.address-modal-title {
    font-size: 16px;
    font-weight: 700;
}
.pac-container{
    z-index: 10000;
}