.returns {
    h2 {
        font-weight: 700;
        text-align: center;
    }

    .returns-content {
        margin-top: 15px;

        p {
            font-size: 16px;
        }

        h5 {
            font-weight: 700;
            margin-bottom: 15px;
        }
    }
}