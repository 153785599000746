.home {
  /* BANNERS */
  .banners {
    .banner-video {
      video {
        width: 100%;
      }
    }

    .swiper-wrapper {
      height: 100vh;
    }

    .banner-image {
      height: 90vh;
      width: 100% !important;
    }

    .swiper-button-next {
      height: 50px !important;
      width: 50px !important;
    }

    .swiper-button-prev {
      height: 50px !important;
      width: 50px !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      background-color: #fff;
      font-size: 15px !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      font-weight: 700;
      color: #000;
    }
  }

  /* BESTSELLERS */
  .bestsellers {
    .section-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      h2 {
        font-size: 25px;
        font-weight: 700;
      }

      .shop-products {
        color: #000;
        font-weight: 400;
      }
    }

    .swiper-button-next {
      height: 50px !important;
      width: 50px !important;
    }

    .swiper-button-prev {
      height: 50px !important;
      width: 50px !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      background-color: #fff;
      font-size: 15px !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      font-weight: 700;
      color: #000;
    }
  }

  /* TWO CATEGORIES */
  .two-categories {
    background-color: rgba(244, 244, 244, 0.8);
    padding: 60px;

    .single-category-box {
      position: relative;
      img {
        width: 100%;
        border-radius: 10px;
      }

      .category-box-details {
        text-align: center;

        h4 {
          color: #fff;
          font-weight: 700;
          font-size: 35px;
        }

        .link-btn {
          background-color: #fff;
          border-radius: 5px;
          color: #000;
          border-color: #fff;
          font-weight: 600;
          margin-top: 10px;
          font-size: 15px;
          padding: 10px 25px;
        }
      }

      .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 10px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.4);
      }
    }
  }

  /* FESTIVAL BOX */
  .festival-section {
    .festival-box {
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 4px 4px 12px;

      img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .festival-text {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h4 {
          font-size: 35px;
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          margin-bottom: 0px;
        }

        .link-btn {
          background-color: #000;
          border-radius: 5px;
          color: #fff;
          border-color: #000;
          font-weight: 600;
          margin-top: 15px;
          font-size: 15px;
          padding: 10px 25px;
        }
      }
    }
  }

  /* SHOP FEED */
  .shop-feed {
    h2 {
      font-size: 35px;
      font-weight: 700;
      text-align: center;
    }

    .video-box {
      border-radius: 10px;
      video {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .instagram-feed-videos {
      margin-top: 20px;
    }

    .swiper-button-next {
      height: 50px !important;
      width: 50px !important;
    }

    .swiper-button-prev {
      height: 50px !important;
      width: 50px !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      background-color: #fff;
      font-size: 15px !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      font-weight: 700;
      color: #000;
    }
  }

  /* BROWBAR BY REEMA */
  .browbar-by-reema {
    h2 {
      font-weight: 700;
      text-align: center;
    }

    p {
      font-weight: 400;
      margin-bottom: 10px;
      text-align: center;
    }

    .browbar-banner {
      img {
        width: 100%;
        height: auto;
      }
    }

    .youtube-video {
      margin-top: 25px;

      .video-frame {
        width: 100%;
      }

      .glam-link {
        display: block;
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 22px;
        margin-top: 10px;
        text-decoration: none;
        color: #000;

        .right-icon {
          margin-left: 5px;
        }
      }

      .glam-link:hover {
        .right-icon {
          margin-left: 10px;
        }
      }
    }
  }

  /* ART OF THE BROW */
  .art-of-the-brow {
    h2 {
      font-weight: 700;
      text-align: center;
    }

    p {
      font-weight: 400;
      margin-bottom: 0px;
      text-align: center;
    }

    .youtube-video {
      margin-bottom: 25px;
      .video-frame {
        width: 100%;
      }

      .glam-link {
        display: block;
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 22px;
        margin-top: 10px;
        text-decoration: none;
        color: #000;

        .right-icon {
          margin-left: 5px;
        }
      }

      .glam-link:hover {
        .right-icon {
          margin-left: 10px;
        }
      }
    }
  }

  /* TESTIMONIALS */
  .testimonials {
    .testimonial-card {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px 50px;
      border-radius: 5px;
      background-image: linear-gradient(to top left, #fbc0d0, #eeeeff, #e0ffff);

      .user-avatar {
        margin-left: auto;
        margin-right: auto;
        height: 120px;
        width: 120px;
        border-radius: 50%;

        img {
          width: 100%;
          object-fit: none;
          border-radius: 50%;
        }
      }

      .ratings {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          text-align: center;
          margin-right: 10px;
        }
      }

      .description {
        margin-top: 15px;

        h4 {
          font-weight: 600;
        }
      }
    }

    .swiper-scrollbar-drag {
      background-color: rgba($color: #000000, $alpha: 0.3);
    }
  }

  /* GIFT OF GLAM */
  .gift-of-glam {
    h2 {
      font-weight: 700;
      text-align: center;
    }

    .youtube-video {
      padding-top: 20px;
      padding-bottom: 20px;

      .video-frame {
        width: 100%;
      }

      .glam-link {
        display: block;
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 25px;
        margin-top: 10px;
        text-decoration: none;
        color: #000;

        .right-icon {
          margin-left: 5px;
        }
      }

      .glam-link:hover {
        .right-icon {
          margin-left: 10px;
        }
      }
    }
  }

  /* BBR BENEFITS */
  .benefits {
    background-color: #000;
    text-align: center;

    h2 {
      color: #fff;
      font-weight: 700;
    }

    .benefits-card {
      text-align: center;

      h5 {
        color: #fff;
        font-size: 15px;
        margin-top: 10px;
        line-height: 25px;
      }
    }
  }

  /* PRESS BANNER */
  .press-banner {
    img {
      width: 100%;
      height: auto;
    }
  }

  .about-bbr {
    background-color: #fbf8f3;
    .about-row {
      padding: 30px 10px;
    }
    .image-col {
      display: flex;
      justify-content: center;
    }
    .reema-khan-img {
      width: 60%;
      height: auto;
      border-radius: 50%;
    }

    .description-col {
      display: flex;
      align-items: center;
      border-left: 1px solid #b67e87;
      .des-div {
        padding-left: 40px;
      }
    }
  }
}
