.about-us {
    padding-top: 0px !important;
    .section {
        img {
            width: 100%;
            height: auto;
        }
    }

    h4 {
        margin-bottom: 15px;
        font-weight: 700;
    }

    h5 {
        margin-bottom: 15px;
        font-weight: 600;
    }

    p {
        font-size: 15px;
    }

    video {
        width: 100%;
        height: auto;
    }

    .shopping-collection {
        h2 {
            font-weight: 700;
            text-align: center;
        }

        .collection-card {
            text-decoration: none;

            img {
                width: 100%;
                height: auto;
            }

            .collection-link {
                display: block;
                margin-top: 5px;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 22px;
                text-decoration: none;
                color: #000;

                .right-icon {
                    margin-left: 5px;
                }
            }

            .collection-link:hover {
                .right-icon {
                    margin-left: 10px;
                }
            }
        }
    }

    .international-artist {
        background-color: #e4e1df;
        margin-bottom: 50px;
        // color: white;
        padding: 20px;
        border-radius: 10px;

        .video-col{
            h5{
                font-size: 28px;
            }
            video{
                border-radius: 10px;
            }
        }
        .content{
            border-left: 1px solid #B6B6B6 !important;
        }
    }

    .second-row{
        margin-bottom: 50px;
        .video-col{
            border-left: 1px solid #B6B6B6;
        }
        video{
            border-radius: 10px;
        }
    }

    .third-row{
        background-color: #e4e1df;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 50px;
        .reema-img{
            border-right: 1px solid #B6B6B6;
            img{
                border-radius: 10px;
            }
        }
    }

    .last-row{
        margin-bottom: 50px;
        video{
            border-radius: 10px;
        }
        .video-col{
            border-left: 1px solid #B6B6B6;
        }
    }

    .explore-tools{
        h5{
            font-size: 28px;
        }
        .tools-div{
            padding: 10px;
            h5{
                font-size: 20px;
            }
        }

        img{
            width: 90%;
            height: auto;
            margin-bottom: 20px;
            border-radius: 10px;
        }
    }
}