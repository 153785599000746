.new-arrivals {
    h2 {
        font-weight: 700;
        text-align: center;
    }

    .banner {
        margin: 20px 0px;
        img {
            width: 100%;
            height: auto;
        }
    }

    .heading {
        p {
            font-weight: 400;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .products-list {
        .product-stats {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            p {
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .no-match-found {
            h5 {
                font-weight: 700;
            }

            p {
                font-weight: 500;
            }

            .no-match-category {
                .collection-card {
                    text-decoration: none;
                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }

                    .collection-link {
                        display: block;
                        text-decoration: none;
                        color: #000;
                        margin-top: 5px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}