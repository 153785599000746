.terms-of-use {
    h2 {
        font-weight: 700;
        text-align: center;
    }

    .terms-of-use-content {
        margin-top: 15px;

        p {
            font-size: 16px;
        }

        h5 {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 15px;
        }
    }
}