.contact-us {
    h2 {
        font-weight: 700;
        text-align: center;
    }

    p {
        text-align: center;
        margin-bottom: 0px;
    }

    .contact-us-form {
        margin: 20px 0;
        border-radius: 5px;

        h5 {
            font-weight: 700;
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }

    .submit-btn {
        background-color: #000;
        font-size: 15px;
        border-color: #000;
        padding: 10px 30px;
        border-radius: 5px !important;
    }
}