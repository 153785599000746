.wholesale-page {
    .page-wrap {
        padding: 50px 0;

        h1 {
            font-weight: 700;
            font-size: 30px;
        }

        .step-box {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: #F4F4F4;
            padding: 20px 20px 15px 20px;
            text-align: center;

            .step-number {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                border: 2px solid #000000;
                margin-left: auto;
                margin-right: auto;

                h5 {
                    font-size: 22px;
                    font-weight: 700;
                }
            }

            .step-number.active {
                background-color: #000;

                h5 {
                    color: #FFF;
                }
            }

            .step-details {
                margin-top: 15px;

                h5 {
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 15px;
                    margin-top: 5px;
                }

                span {
                    font-size: 14px;
                    display: block;
                    font-style: italic;
                }
            }
        }

        .details {
            margin-top: 20px;

            h5 {
                font-weight: 600;
                font-size: 18px;
            }
        
            .requirements-list {
                margin-bottom: 0px;

                li {
                    margin-bottom: 5px;
                    font-weight: 500;
        
                    a {
                        margin-left: 5px;
                        text-decoration: none;
                    }
                }
            }
            
            .form {
                background-color: #F4F4F4;
                border-radius: 5px;
                width: 100%;
                padding: 20px;
                margin-top: 20px;

                h5 {
                    font-weight: 700;
                    font-size: 22px;
                    margin-bottom: 15px;
                }

                .password-icon {
                    background-color: #FFF;
                    padding: 0px;
                    position: absolute;
                    top: 38px;
                    right: 15px;
                    border: 0px solid transparent;
                }

                .password-icon:hover {
                    background-color: #FFF;
                }

                .password-icon svg {
                    fill: #000;
                }

                .conditions-box {
                    margin-bottom: 10px;
                    margin-top: 10px;
                    
                    .form-check-label {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }

            .terms-conditions {
                margin-bottom: 20px;
                margin-top: 20px;
                background-color: #F4F4F4;
                padding: 20px;
                border-radius: 5px;

                h5 {
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 15px;
                    margin-top: 5px;
                }

                .terms-conditions-box {
                    width: 100%;
                    height: 300px;
                    overflow: auto;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    background-color: #FFF;
                    border: 1px solid rgba($color: #000000, $alpha: 0.1);
                    border-radius: 5px;
                    margin-top: 15px;
                    padding: 10px 15px;

                    h5 {
                        font-size: 16px;
                        margin-top: 0px;
                        font-weight: 600;
                        line-height: 22px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }

            .cancel-btn {
                background-color: #FFF;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 15px;
                border-color: #000;
                color: #000;
                border-width: 2px;
            }

            .proceed-btn {
                background-color: #000;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 15px;
                border-color: #000;
            }

            .section-heading {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .documents-heading {
                    font-weight: 700;
                    font-size: 22px;
                    margin-bottom: 0px;
                    margin-top: 0px;
                }

                .add-document {
                    background-color: transparent;
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    border-color: transparent;
                    padding: 0px;
                }
            }

            .small-width {
                width: 200px;
                padding: 0px 15px;
                height: 35px;
            }

            .file-input {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .delete-btn {
                    background-color: transparent;
                }
            }
        }
    }

    .download-btn{
        // border: 1px solid red;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}