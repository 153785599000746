.no-page-found {
    h1 {
        font-weight: 700;
    } 

    .category-box {
        border: 2px solid #E5E5E5;
        border-radius: 5px;
        padding: 15px 20px;

        h5 {
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .shopping-collection {
        h2 {
            font-weight: 700;
            text-align: center;
        }

        .collection-card {
            text-decoration: none;

            img {
                width: 100%;
                height: auto;
            }

            .collection-link {
                display: block;
                margin-top: 5px;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 22px;
                text-decoration: none;
                color: #000;

                .right-icon {
                    margin-left: 5px;
                }
            }

            .collection-link:hover {
                .right-icon {
                    margin-left: 10px;
                }
            }
        }
    }
}