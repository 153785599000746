.footer {

    /* COPYRIGHT INFORMATION */
    .copyright-info {
        background-color: #E3E3E5;
        padding: 15px 0;

        .copyright-links {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .info {
                align-items: center;
                p {
                    font-weight: 400;
                    color: #000;
                    font-size: 13px;
                    margin-bottom: 0px;
                }

                .links {
                    margin-top: 5px;
                    a {
                        color: #000;
                        font-size: 13px;
                        margin-right: 20px;
                    }
                }
            }

            .security-verifications {
                align-items: center;
                img {
                    height: 50px;
                    width: auto;
                    margin-left: 30px;
                }
            }
        }
    }

    /* FOOTER INFORMATION */
    .footer-links {
        background-color: #000;
        padding: 60px 0;

        .become-member {
            h5 {
                font-weight: 700;
                color: #FFF;
                font-size: 18px;
            }

            p {
                font-weight: 400;
                color: #FFF;
                font-size: 14px;
            }

            .newsletter-section {
                display: flex;
                align-items: center;

                .form-control {
                    background-color: #000;
                    font-size: 14px;
                    border-radius: 5px !important;
                    color: #FFF;
                }

                .form-control:focus {
                    box-shadow: none !important;
                }

                .form-control::placeholder {
                    color: #FFF;
                    font-size: 13px;
                }

                .submit-btn {
                    background-color: #000;
                    color: #FFF;
                    border: 1px solid #FFF;
                    font-size: 14px;
                    margin-left: 15px;
                }

                .submit-btn:hover {
                    background-color: #FFF;
                    color: #000;
                }
            }
        }

        .links-list {
            h5 {
                color: #939393;
                font-size: 13px;
                font-weight: 700;
                line-height: 2rem;
                margin-bottom: 0px;
            }

            .link-list-item {
                color: #FFF;
                font-size: 13px;
                text-decoration: none;
                line-height: 2rem;
                text-transform: uppercase;
                font-weight: 600;
                display: block;
            }
        }
    }
}