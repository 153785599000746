.new-header {
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);
    
    /* TOP HEADER */
    .top-header {
        padding: 10px 0px;
        background-color: #000;
        text-align: center;

        h5 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #FFF;
        }
    }

    /* TOP HEADER TWO */
    .top-header-two {
        padding: 10px 0px;
        background-color: #E5E3E3;
        text-align: center; 
        margin-left: 0px;
        position: relative;

        h5 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
            margin-top: 0px;
            color: #000;
            text-align: center;
        }
    }

    .tabs-switcher {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;

        .switcher-box {
            background-color: #E5E3E3;
            cursor: pointer;
            padding: 10px 15px;

            h5 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
            }

            a {
                text-decoration: none;
                color: #000;
            }
        }

        .switcher-box.active {
            background-color: #FFF;
        }
    }

    .free{
        // border: 1px solid black;
        padding: 0px;
    }
    .whole-sale{
        display: flex;
        flex-wrap: nowrap;
        padding: 0px 10px;
        margin: 0px;
        // border: 1px solid red;
        margin-right: 0px;
        // padding-left: 0px;
        background-color: #E5E3E3;
        z-index: 1;

        h5{
            padding: 0px 10px;
            font-size: 16px;
            font-weight: 600;
        }
        .wholesale{
            // border: 1px solid blue;
            background-color: white;
            padding-top: 10px;
        }
        .retail{
            background-color: #E5E3E3;
            padding-top: 10px;
            width: 100%;
            cursor: pointer;
            // border: 1px solid green;
            a{
                color: black;
                margin-top: 10px;
                text-decoration: none;
            }
        }
        .tab-row{
            display: flex;
            flex-wrap: nowrap;
        }
}

    /* HEADER WRAP */
    .new-header-wrap {
        padding: 15px 0;
        background-color: #FFF;

        .header-flex {
            display: flex;
            align-items: center;

            /* HEADER RIGHT */
            .header-right {
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
        
                .header-actions {
                    position: relative;
                    margin-right: 20px;
        
                    .action-icon {
                        font-size: 25px;
                        fill: #000;
                    }
        
                    .count {
                        background-color: #000;
                        position: absolute;
                        right: -5px;
                        top: -5px;
                        color: #FFF;
                        height: 15px;
                        width: 15px;
                        border-radius: 100%;
                        font-weight: 500;
                        font-size: 10px !important;
                        text-align: center;
                        line-height: 15px;
                    }
                }
            }
        
            /* LOGO */
            .logo {
                text-align: center;
                width: 33%;
        
                img {
                    width: 90px;
                    height: auto;
                }
            }
        
            /* HEADER LEFT */
            .header-left {
                width: 33%;
        
                .main-menu {
                    .menu-link {
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 14px;
                        color: #000;
                        padding-left: 0px;
                        padding-right: 30px;
                    }
        
                    .shop-dropdown-menu {
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 14px;
                        color: #000;
                        padding-left: 0px;
                        padding-right: 30px;
        
                        .dropdown-toggle {
                            color: #000000;
                        }
        
                        .dropdown-toggle::after {
                            display: none;
                        }
        
                        .dropdown-menu {
                            position: absolute;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .new-header-wrap-scroll{
        position: fixed;
        z-index: 10;
        width: 100%;
        top: 0;
    }
}

/* NEW DROPDOWN */
.new-dropdown {
    position: absolute;
    width: 100%;
    background-color: #FFF;
    border: 1px solid #E2E9E1;
    padding: 30px 0px;
    z-index: 10;

    .dropdown-items {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .column {
        padding-right: 60px;
        .dropdown-link {
            text-decoration: none;
            color: #000;
            font-weight: 600;
            font-size: 16px;
            display: block;
            margin-bottom: 15px;
            cursor: pointer;
        }
    
        .small-menu-link {
            display: block;
            font-size: 13px;
            font-weight: 400;
            color: #000;
            text-decoration: none;
            margin-bottom: 15px;
            cursor: pointer;
        }
    }

    .right-border {
        border-right: 1px solid #E2E9E1;
    }
}

.new-dropdown.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 500ms ease;
}

.new-dropdown.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 500ms ease;
}

.mobile-dropdown {
    display: none;
}