.diy-brows-product-card {
    width: 100%;
    height: 100%;
    border: 0px solid transparent;
    background-color: #F4F4F4;
    
    .product-img {
        background-color: #E8E8E8;

        .front-img {
            display: block;
        }

        .back-img {
            display: none;
        }
    }

    .product-img:hover {
        .front-img {
            display: none;
        }

        .back-img {
            display: block;
        }
    }

    .product-img img {
        width: 100%;
        height: auto;
    }

    .wishlist-option {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #FFF;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .wishlist-icon {
            font-size: 20px;
        }
    }

    .product-desc {
        background-color: #F4F4F4;
        padding: 10px 15px;
        h5 {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
        }
        h3{
            text-align: left;
        }

        .ratings {
            margin-top: 5px;
            display: flex;
            align-items: center;

            img {
                margin-right: 3px;
            }

            p {
                margin-bottom: 0px;
                color: #555555;
                font-size: 14px;
                margin-left: 5px;
            }
        }

        .add-to-bag {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background-color: #FFF;
            border: 1px solid rgb(0, 0, 0, 0.1);
            border-radius: 0px !important;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;

            h5 {
                color: #000;
                margin-bottom: 0px;
            }
        }

        .add-to-bag:hover {
            border: 1px solid rgb(0, 0, 0, 1);
        }
    }
}

.video-row{
    .video-box-section{
        height: 100%;
    }
    .swiper-button-next {
        height: 50px !important;
        width: 50px !important;
    }

    .swiper-button-prev {
        height: 50px !important;
        width: 50px !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        background-color: #FFF;
        font-size: 15px !important;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        font-weight: 700;
        color: #000;
    }
    a{
        text-decoration: none;
    }
}