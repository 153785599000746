/* VARIABLES */
@import "./variables.scss";

/* LAYOUT AND PAGES STYLESHEETS */
@import "./layout/header.scss";
@import "./layout/footer.scss";
@import "./pages/home/home.scss";
@import "./pages/press/press.scss";
@import "./pages/affiliate/affiliate.scss";
@import "./pages/returns/returns.scss";
@import "./pages/terms-of-use/terms-of-use.scss";
@import "./pages/privacy-policy/privacy-policy.scss";
@import "./pages/contact-us/contact-us.scss";
@import "./pages/new-arrivals/new-arrivals.scss";
@import "./pages/eye/eye.scss";
@import "./pages/lips/lips.scss";
@import "./pages/brow/brow.scss";
@import "./pages/face/face.scss";
@import "./pages/bestsellers/bestsellers.scss";
@import "./pages/diy-brows/diy-brows.scss";
@import "./pages/diy-brows/new-diy-brows.scss";
@import "./pages/account/account.scss";
@import "./pages/sets/sets.scss";
@import "./pages/cart/cart.scss";
@import "./pages/checkout/checkout.scss";
@import "./pages/about-us/about-us.scss";
@import "./pages/dashboard/dashboard.scss";
@import "./pages/wishlist/wishlist.scss";
@import "./pages/product-detail/product-detail.scss";
@import "./responsive.scss";
@import "./pages/brow-quiz/brow-quiz.scss";
@import "./layout/loading.scss";
@import "./pages/no-page-found/no-page-found.scss";
@import "./pages/account/salon-registration.scss";
@import "./layout/new-header.scss";
@import "./pages/brow-kit/brow-kit.scss";

body {
  font-family: $font-family-base;
  overflow-x: hidden;
  margin: auto;
}

p {
  font-family: "Figtree", sans-serif;
}

span {
  font-family: "Figtree", sans-serif !important;
  font-size: 15px !important;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1500px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ptb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ptb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.ptb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.ptb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.ptb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.border-top-section {
  border-top: 1px solid #d5d5d5 !important;
}

.form-group {
  position: relative;
}

.validation-error {
  font-family: "Figtree", sans-serif;
  position: relative;
  margin-left: 2px;
}

.validation-error-select {
  font-family: "Figtree", sans-serif;
  position: relative;
  margin-left: 2px;
}

.validation-error-phone {
  font-family: "Figtree", sans-serif;
  position: relative;
  margin-left: 75px;
}

.edit-btn {
  padding: 0px;
  font-family: "Figtree", sans-serif;
  color: grey;
  font-style: italic;
  background-color: #fff;
  border: 0px solid transparent;
  font-size: 14px;
  font-weight: 600;
}

.edit-btn:hover {
  text-decoration: underline;
  background-color: #fff;
  color: grey;
}

.delete-btn {
  padding: 0px;
  font-family: "Figtree", sans-serif;
  color: red;
  font-style: italic;
  background-color: #fff;
  border: 0px solid transparent;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
}

.delete-btn:hover {
  text-decoration: underline;
  background-color: #fff;
  color: red;
}

.form-label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-control {
  position: relative;
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  height: 45px;
  box-shadow: none;
  padding-left: 20px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 15px;
}

.form-control::placeholder {
  color: #8e8e90;
  font-size: 14px;
}

.form-control:focus {
  border: 1px solid #000;
  box-shadow: none !important;
}

.select-control__control {
  height: 45px;
  font-size: 14px;
  border: 1px solid #e2e9e1 !important;
  padding-left: 10px;
  color: #8e8e90;
}

.select-control__indicator-separator {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.phone-number-select .PhoneInputCountry {
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  padding: 15px;
  height: 45px;
  background-color: #fff;
}

.phone-number-select .PhoneInputCountry .PhoneInputCountrySelect option {
  color: #465b52;
  font-size: 15px;
  margin-bottom: 10px;
}

.phone-number-select .PhoneInputInput {
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  height: 45px;
  box-shadow: none;
  color: #161c27;
  font-size: 14px;
  padding: 6px 12px 6px 20px;
  width: 100%;
}

.phone-number-select .PhoneInputInput::placeholder {
  font-size: 14px;
}

.textarea-control {
  display: block;
  width: 100%;
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  box-shadow: none;
  padding-left: 20px;
  padding-top: 20px;
  font-size: 14px;
  margin-bottom: 15px;
}

.product-card {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
  border: 0px solid transparent;

  .product-img {
    background-color: #e8e8e8;

    .front-img {
      display: block;
    }
    .blur-image {
      filter: blur(4px);
      -webkit-filter: blur(4px);
    }

    .back-img {
      display: none;
    }
  }

  .product-img:hover {
    .front-img {
      display: none;
    }

    .back-img {
      display: block;
    }
  }

  .product-img img {
    width: 100%;
    height: auto;
  }

  .wishlist-option {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .wishlist-icon {
      font-size: 20px;
    }
  }

  .product-desc {
    background-color: #f4f4f4;
    padding: 10px 15px;
    h5 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
      font-weight: 600;
    }

    .ratings {
      margin-top: 5px;
      display: flex;
      align-items: center;

      img {
        margin-right: 3px;
      }

      p {
        margin-bottom: 0px;
        color: #555555;
        font-size: 14px;
        margin-left: 5px;
      }
    }

    .add-to-bag {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      background-color: #fff;
      border: 1px solid rgb(0, 0, 0, 0.1);
      border-radius: 0px !important;
      font-weight: 600;
      padding-top: 10px;
      padding-bottom: 10px;

      h5 {
        color: #000;
        margin-bottom: 0px;
      }
    }

    .add-to-bag:hover {
      border: 1px solid rgb(0, 0, 0, 1);
    }
  }
}

.newsletter-modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0px !important;
    }
  }
}

.newsletter-modalbody {
  border-radius: 0px !important;
  height: 465px;
  padding: 0px;

  img {
    width: 100%;
    height: 465px;
    object-fit: cover;
  }

  .newsletter-description {
    padding: 30px 40px;

    h5 {
      font-size: 16px;
      font-style: italic;
    }

    h2 {
      font-weight: 700;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .form-control {
      margin-top: 15px;
    }

    .small-text {
      font-size: 12px;
      margin-bottom: 0px;
      margin-left: 10px;
    }

    .sign-up-now-btn {
      margin-top: 20px;
      font-size: 14px;
      border-color: #000;
      font-weight: 600;
      width: 100%;
      background-color: #000;
    }

    .not-now-btn {
      margin-top: 10px;
      font-size: 14px;
      border-color: #000;
      font-weight: 600;
      color: #000;
      width: 100%;
      background-color: transparent;
      margin-bottom: 10px;
    }
  }
}
