.brow-quiz {
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;

    .quiz-banner {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 100%;
        object-fit: cover;
    }

    .quiz-sections {
        padding: 20px;

        .section {
            text-align: center;
            h4 {
                text-align: center;
                font-weight: 700;
                font-size: 30px;
            }

            p {
                text-align: center;
                font-size: 20px;
            }

            .small-text {
                margin-top: 10px;
                font-size: 14px;
            }

            .take-quiz {
                background-color: #000;
                border-radius: 5px;
                font-weight: 600;
                text-align: center;
                padding: 10px 25px;
                border-color: #000;
            }

            .option-buttons {
                margin-top: 20px;

                .option-img {
                    cursor: pointer;
                    position: relative;
                    text-align: center;
                    border: 2px solid #E5E5E5;
                    border-radius: 5px;
                    padding: 10px;
                    margin-bottom: 15px;

                    .selected-icon {
                        display: none;
                        position: absolute;
                        top: 5px;
                        right: 5px;
                    }

                    h5 {
                        font-size: 17px;
                        margin-bottom: 0px;
                    } 
                }

                .option-img:hover {
                    border: 2px solid #000;
                }

                .option-img.selected {
                    .selected-icon {
                        display: block;
                    }
                }
            }
        }
    }
}