.affiliate {
    h2 {
        font-weight: 700;
        text-align: center;
    }

    .affiliate-form {
        margin: 20px 0;
        border: 1px solid #D5D5D5;
        padding: 20px;
        border-radius: 5px;

        h5 {
            font-weight: 700;
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }

    .affiliate-form-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .divider-line {
        height: 1.5px;
        width: 70%;
        background-color: #000;
    }

    .submit-btn {
        background-color: #000;
        font-size: 15px;
        border-color: #000;
        padding: 10px 30px;
        border-radius: 5px !important;
        margin-top: 10px;
    }
}