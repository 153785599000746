@media (max-width: 575px) {
    /* FOOTER */
    .footer .copyright-info .copyright-links {
        display: block;
    }

    .footer .copyright-info .copyright-links .security-verifications {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    .footer .copyright-info .copyright-links .security-verifications img {
        margin-left: 0px;
    }

    .footer .copyright-info .copyright-links .info p {
        text-align: center;
    }

    .footer .copyright-info .copyright-links .info .links {
        justify-content: center;
    }

    .links-list {
        margin-top: 15px;
    }

    /* GIFT OF GLAM */
    .youtube-video .video-frame {
        height: 300px;
    }

    .home .gift-of-glam .youtube-video .glam-link {
        font-size: 16px;
    }

    /* ART OF THE BROW */
    .home .art-of-the-brow .youtube-video {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .home .art-of-the-brow .youtube-video .glam-link {
        font-size: 16px;
        margin-top: 0px;
    }

    /* BROW BAR BY REEMA */
    .home .browbar-by-reema .youtube-video .glam-link {
        font-size: 16px;
        margin-top: 0px;
    }

    /* HEADER */
    .header .top-header h5 {
        line-height: 25px;
        font-size: 14px;
    }

    .header .top-header-two h5 {
        font-size: 14px;
    }

    .home .bestsellers .section-heading {
        display: block;
        text-align: center;
    }

    .press .articles-content .article-link {
        font-size: 16px;
        margin-top: 0px;
    }

    .press .shopping-collection .collection-card .collection-link {
        font-size: 16px;
    }

    .about-us .about-content {
        padding-left: 0px !important;
        margin-top: 10px;
    }

    .about-us .about-content-2 {
        padding-right: 0px !important;
        margin-top: 10px;
    }

    .about-us h5 {
        font-size: 16px;
    }

    .swiper-wrapper {
        height: 100% !important;
    }

    .banner-image {
        height: 100% !important;
        width: 100% !important;
    }

    .about-us .shopping-collection .collection-card .collection-link {
        font-size: 16px;
    }

    .cart-popup {
        width: 350px; /* 
        right: -120px; */
        margin-left: auto;
        margin-right: auto;
    }

    .cart-popup .products-added .view-cart-btn {
        margin-top: 10px;
    }

    .cart-popup .products-added .paypal-btn {
        margin-top: 10px;
    }

    .cart .summary-heading {
        margin-top: 15px;
    }

    .cart .bestsellers .section-heading {
        display: block;
        text-align: center;
    }

    .cart .cart-items .shopping-items .product-details .product-name {
        font-size: 14px;
    }

    .account .login_wrap {
        margin-top: 15px;
    }

    .checkout .checkout-tabnames .checkout-tabnames-item a {
        font-size: 20px;
    }

    .checkout .checkout-tabnames .checkout-tabnames-item {
        margin-right: 0px;
    }

    .checkout .shipping-address h5 {
        font-size: 14px;
    }

    .select-control__control {
        margin-bottom: 15px;
    }

    .checkout .summary-heading {
        margin-top: 20px;
    }

    .cart-items {
        overflow-x: scroll;
    }

    .cart .cart-items .shopping-items .product-details {
        max-width: 400px;
        width: 400px;
    }

    .cart .cart-items .shopping-items {
        width: 750px;
    }

    .cart .cart-items .shopping-items .product-details p {
        font-size: 14px;
    }

    .qty-width .select-control__control {
        margin-bottom: 0px;
    }

    .product-detail .product-description {
        margin-top: 15px;
    }

    .product-detail .product-description .good-to-know .good-to-know-list {
        display: block;
    }

    .product-detail .product-description .good-to-know .good-to-know-list .list-detail {
        margin-bottom: 10px;
    }

    .product-detail .product-description .quantity-counter {
        margin-bottom: 10px;
        justify-content: center;
    }

    .product-detail .total-reviews .reviews-actions {
        display: block;
        justify-content: center;
        text-align: center;
    }

    .product-detail .total-reviews .reviews-actions .review-count {
        justify-content: center;
        margin-bottom: 10px;
    }

    .user-review-desc {
        margin-top: 15px;
    }

    .product-detail .total-reviews .user-review .user-review-desc .ratings-given .rating-name {
        margin-right: 15px;
    }

    .product-detail .total-reviews .user-review .user-review-desc .client-images-list img {
        height: 60px;
        width: 60px;
        margin-bottom: 10px;
    }

    .product-detail .bestsellers .section-heading {
        text-align: center;
        display: block;
    }

    .product-detail .bestsellers .section-heading .shop-products {
        text-align: center;
    }

    .product-detail .secondary-images-list {
        display: flex;
        height: auto;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: space-between;
    }

    .product-detail .secondary-images-list img {
        height: 80px;
        width: 80px;
    }

    .orders-info {
        margin-top: 15px;
    }

    .account-info {
        margin-top: 15px;
    }

    .resp-margin {
        margin-top: 15px;
    }

    .address-information {
        margin-top: 15px;
    }

    .my-account .address-information .shipping-addresses .address-box {
        margin-bottom: 15px;
    }

    .user-acc-info {
        margin-top: 15px;
    }

    .brow-quiz {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .brow-quiz .quiz-banner {
        border-radius: 0px !important;
    }

    .product-detail .primary-img img {
        width: 100%;
    }

    .product-detail .product-small-images {
        width: 100%;
    }

    .newsletter-modalbody .newsletter-description {
        background-color: #fff;
    }

    .newsletter-modalbody img {
        display: none;
    }

    .newsletter-modal {
        padding-left: 0px !important;
    }

    .product-detail .images-list .primary-img .main-img-loading {
        height: 400px;
    }

    .product-detail .product-detail-img-slider .small-img-loading {
        height: 70px;
    }

    .new-header .new-header-wrap .header-left {
        width: auto !important;
    }

    .new-dropdown {
        display: none;
    }

    .main-menu {
        .nav-link {
            font-size: 14px;
            font-weight: 600;
            color: #000;
            text-transform: uppercase;
        }
    }

    .mobile-hide {
        display: none;
    }

    .mobile-dropdown {
        display: block !important;
        .dropdown-menu {
            .dropdown-item {
                font-size: 14px;
                font-weight: 400;
            }

            .mobile-inner-dropdown {
                .dropdown-toggle {
                    padding-top: 10px;
                    padding-bottom: 0px;
                    padding-left: 16px;
                    padding-right: 16px;
                }

                .dropdown-menu {
                    padding-top: 0px;
                    border: 0px solid transparent;
                }
            }
        }
    }
    .home {
        .about-bbr {
            .description-col {
                border-left: none;
                padding-top: 30px;
            }
        }
    }

    .new-header .new-header-wrap .header-flex {
        justify-content: space-between;
    }

    .new-header .new-header-wrap .header-flex .header-right .header-actions {
        margin-right: 10px !important;
    }

    .new-header .new-header-wrap .header-flex .header-right .header-actions:last-child {
        margin-right: 0px !important;
    }

    .new-header .new-header-wrap .header-flex .logo {
        width: auto !important;
    }

    .new-header .new-header-wrap .header-flex .header-right {
        width: auto !important;
    }

    .new-header .top-header h5 {
        line-height: 20px !important;
        font-size: 14px !important;
    }

    .new-header .tabs-switcher {
        position: relative !important;
        margin-top: 10px;

        .switcher-box {
            width: 100%;
        }
    }

    .new-header .top-header-two {
        padding-bottom: 0px !important;
    }

    .new-header .top-header-two h5 {
        font-size: 14px !important;
    }

    .home .two-categories {
        padding: 10px !important;
    }

    .home .two-categories .single-category-box {
        margin-bottom: 20px;
    }
}
